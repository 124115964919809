<template>
  <div class="login">正在链接，请稍后....</div>
</template>

<script>
import * as utils from "@/utils";
export default {
  created() {
    const auth = this.$route.query.token;
    utils.set("auth", auth);
  },
};
</script>

<style lang="scss" scoped>
.login {
  padding-top: 200px;
  text-align: center;
  font-size: 28px;
  min-height: 100vh;
}
</style>
